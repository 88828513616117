export const weekdayHelper = weekday => {
  switch (weekday) {
    case 0:
      return "Søndag";
    case 1:
      return "Mandag";
    case 2:
      return "Tirsdag";
    case 3:
      return "Onsdag";
    case 4:
      return "Torsdag";
    case 5:
      return "Fredag";
    case 6:
      return "Lørdag";
    default:
      console.error("Ukjent dag");
      return "Ukjent dag";
  }
}
