import { api } from "../../config";
import { fetchWithOptionalVippsToken, fetchWithVippsToken } from "../auth";

export const fetchCoursesForPlace = async (placeId) => {
  if (!placeId || placeId.length !== 24) return;
  try {
    const res = await fetchWithOptionalVippsToken(`${api.baseUrl}/places/${placeId}/courses`);
    const data = await res.json();
    data.sort((a, b) => a.timeFrom.localeCompare(b.timeFrom));
    data.sort((a, b) => a.dates[0].localeCompare(b.dates[0]));
    return data;
  } catch (error) {
    console.error(error);
  }
}

export const editCourse = async (editedCourse) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses/${editedCourse._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedCourse)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const addCourse = async (newCourse) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newCourse)
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}

export const deleteCourse = async (id) => {
  try {
    const res = await fetchWithVippsToken(`${api.baseUrl}/courses/${id}`, {
      method: 'DELETE'
    });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log(err);
  }
}
