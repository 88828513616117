import { useContext, useEffect, useState } from 'react';
import './payment.css';
import { LoggedInUserContext } from '../../../context';
import { useParams } from 'react-router-dom';
import { getPayment } from '../../../functions/vipps';
import { VippsPayment } from '../../Vipps/VippsPayment';
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import { Faqs } from '../../Faqs/Faqs';
import { NotLoggedIn } from '../../Errors/NotLoggedIn';

export const Payment = () => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [payment, setPayment] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (!params.paymentId) {
      return;
    }
    if (!loggedInUser) {
      return;
    }
    const fetchPayment = async () => {
      const data = await getPayment(params.paymentId);
      if (!data) return;
      setPayment(data);
    }

    fetchPayment();
  }, [loggedInUser, params.paymentId]);

  // useEffect(() => {
  //   console.log("🚀 ~ file: Payment.js:35 ~ useEffect ~ payment:", payment);
  // }, [payment]);

  // if (!loggedInUser) return (
  //   <div className="payment content-margined">
  //     <div className="content">
  //       <p>Du er ikke logget inn</p>
  //     </div>
  //   </div>
  // );

  return (
    <div className="payment content-margined">
      {!loggedInUser ?
        <NotLoggedIn />
      :
        <>
          <TitleWithSeparator title="Ordrebekreftelse" />
          <div className="content">
            <VippsPayment payment={payment} />
            <Faqs />
          </div>
        </>
      }
    </div>
  );
}
