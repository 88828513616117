import PersonCard from '../../Cards/PersonCard';
import TitleWithSeparator from '../../Separator/TitleWithSeparator';
import './about.css';
import AboutCompany from '../../About/AboutCompany';
import { useEffect, useState } from 'react';
import { fetchInstructors } from '../../../functions/fetch';

const AboutPage = () => {
  const [instructors, setInstructors] = useState([]);

  useEffect(() => {
    const loadInstructors = async () => {
      const data = await fetchInstructors();
      setInstructors(data);
    }

    loadInstructors();
  }, []);

  return (
    <div className="content-margined">
      <div className='about'>
        <AboutCompany />
        <div className="about-instructors">
          <TitleWithSeparator title="Våre instruktører" />
          <div className="instructors">
            {instructors && instructors.length > 0 && instructors.map((i, index) => (
              <PersonCard key={index} user={i} />
            ))}
            {!instructors || !instructors.length && <p>Her mangler informasjon om våre instruktører</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
