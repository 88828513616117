import './personCard.css';
let personImg;

const PersonCard = ({ user }) => {
  const fullName = user.name ? user.name : `${user.firstName} ${user.lastName}`;

  return (
    <div className="person-card">
      {personImg && 
        <div className="top">
          <img src={personImg} alt={user.name || 'Instruktør'} />
        </div>
      }
      <div className="bottom">
        <h3 className="name">{fullName}</h3>
      </div>
    </div>
  );
}

export default PersonCard;
