import { useState } from 'react';
import './banner.css';
import { faCircleCheck, faCircleExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NPBanner = ({ children, classes = '' }) => {
  const [dismissed, setDismissed] = useState(localStorage.getItem('np1-banner-dismissed'));

  const dismiss = () => {
    localStorage.setItem('np1-banner-dismissed', true);
    setDismissed(true);
  }

  if (!children) {
    console.error('No children provided to Banner');
    return null;
  }
  if (classes) classes = ' ' + classes;
  return dismissed ? null : <div className={"np-banner" + classes}>
    {children}
    <p className='close' onClick={dismiss}>Lukk</p>
  </div>
}

export const Banner = ({ type, message, showIcon = true }) => {
  if (!message) return null;
  if (type !== 'error' && type !== 'success' && type !== 'warning') return null;
  let icon;
  switch (type) {
    case 'error':
      icon = faCircleExclamation;
      break;
    case 'success':
      icon = faCircleCheck;
      break;
    case 'warning':
      icon = faTriangleExclamation;
      break;
  }
  const faIcon = showIcon && icon ? <FontAwesomeIcon icon={icon} /> : null;
  return <div className={`banner ${type}`}>{faIcon}<p>{message}</p></div>
}

// export const ErrorBanner = ({ options }) => {
//   return options.message ? <div classes='error-banner'>
//     <p>{options.message}</p>
//   </div> : null;
// }

// export const SuccessBanner = ({ options }) => {
//   return options.message ? <div classes='success-banner'>
//     <p>{options.message}</p>
//   </div> : null;
// }

// export const WarningBanner = ({ options }) => {
//   return options.message ? <div classes='warning-banner'>
//     <p>{options.message}</p>
//   </div> : null;
// }
