import { useState, useEffect } from 'react';
import CourseCards from '../Cards/CourseCard';
import TitleWithSeparator from '../Separator/TitleWithSeparator';
import './coursesFrontPage.css';
import { fetchPlaces } from '../../functions/fetch';

const CoursesFrontPage = () => {
  const [coursePlaces, setCoursePlaces] = useState(null);

  useEffect(() => {
    const fetchFrontPageCourses = async () => {
      if (coursePlaces) return;
      const data = await fetchPlaces();
      if (data) {
        setCoursePlaces(data);
      } else {
        console.error('Could not fetch front page course places');
      }
    }

    fetchFrontPageCourses();
  }, [coursePlaces]);

  return (
    <div className='places'>
      <TitleWithSeparator title="Kurssteder" />
      <div className="description">
        <p>Svømming skal være gøy, og lærerikt. Gjør barnet ditt kjent og trygg i vannet allerede som babyer, samtidig som dere skaper nye minner og tillit sammen.</p>
      </div>
      <div className="course-cards">
        {coursePlaces && Array.isArray(coursePlaces) && coursePlaces.length > 0 && coursePlaces.map((place, i) =>
          <CourseCards key={i} options={place} />
        )}
      </div>
    </div>
  );
}

export default CoursesFrontPage;
