import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { weekdayHelper } from '../../functions/helpers';
import { useContext, useEffect, useState } from 'react';
import { EditCourseTableRow } from './EditCourseTableRow';
import { LoggedInUserContext } from '../../context';
import { getUsers } from '../../functions/fetch';

export const CourseTableRow = ({ options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [expanded, setExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [clickCounter, setClickCounter] = useState(0);
  const [course, setCourse] = useState(options.data);
  const [fetchedParticipants, setFetchedParticipants] = useState(false);

  // useEffect(() => {
  //   const getUsersInCourse = async () => {
  //     if (!expanded) return;
  //     if (!course.participants || !course.participants.length) return;
  //     if (!loggedInUser || !(loggedInUser.roles.includes('instructor') || loggedInUser.roles.includes('admin'))) return;
  //     if (fetchedParticipants) return;
  //     try {
  //       const usersInCourse = await getUsers(course.participants);
  //       setCourse({ ...course, participants: usersInCourse });
  //       setFetchedParticipants(true);
  //     } catch (e) {
  //       console.log('Error getting users in course', e);
  //     }
  //   }
  //   getUsersInCourse();
  // }, [expanded, course.participants]);

  if (!course) return null;

  const availableSpots = !course.participants || course.participants.length >= course.maximumParticipants ? 0 : (course.maximumParticipants || 0) - course.participants.length;

  const instructorsForCourse = course.instructors && course.instructors.length > 0 ? course.instructors.map(instructor => options.instructors.find(i => i.id === instructor)) : [];

  const timeFromDate = new Date(`1970-01-01 ${course.timeFrom}`);
  const timeToDate = new Date(`1970-01-01 ${course.timeTo}`);
  const durationDate = new Date(timeToDate.getTime() - timeFromDate.getTime());

  const calculateDates = (numOrDate) => {
    if (typeof numOrDate !== 'number' || numOrDate <= 0) return;
    const dates = [];
    // const date = new Date(course.dates[0]);
    for (let weekNum = 0; weekNum < numOrDate; weekNum++) {
      const newDate = new Date(course.dates[0]);
      newDate.setDate(newDate.getDate() + weekNum * 7);
      dates.push(newDate);
    }
    setCourse({ ...course, dates });
  }

  const setStartDate = date => {
    const dates = course.dates;
    dates[0] = date;
    setCourse({ ...course, dates});
    calculateDates(dates.length);
  }

  const rowClick = (e) => {
    if (!loggedInUser || !loggedInUser.roles.includes('admin')) return;
    setClickCounter(clickCounter + 1);
    setTimeout(() => {
      // console.log('Done listening for clicks');
      if (clickCounter >= 1) setEditMode(true);
      setClickCounter(0);
    }, 250);
  }

  const saveEdited = async () => {
    if (!loggedInUser.roles.includes('admin')) return;
    if (course._id) {
      await options.saveEditedCourse(course);
    } else {
      await options.saveNewCourse(course);
    }
    setEditMode(false);
  }

  const deleteCourse = async () => {
    if (!loggedInUser.roles.includes('admin')) return;
    const res = await options.removeCourse(course._id);
    if (res) {
      setEditMode(false);
      // setCourse(null);
    }
  }

  return editMode && loggedInUser && loggedInUser.roles.includes('admin') ?
    <>
      <EditCourseTableRow course={course} instructors={options.instructors} options={{ saveEdited, setEditMode, setStartDate, setCourse, calculateDates, deleteCourse }} />
    </>
    :
    <>
      <tr onClick={rowClick}>
        <td className='pointer' onClick={() => setExpanded(!expanded)}>
          {expanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
        </td>
        <td>{course.participantsAge}</td>
        <td>{course.experienceLevel}</td>
        <td>{course.timeFrom} - {course.timeTo}</td>
        <td>{course.dates && course.dates.length ? weekdayHelper(new Date(course.dates[0]).getDay()) : new Date()}</td>
        <td>{course.dates && course.dates.length ? new Date(course.dates[0]).toLocaleDateString() : new Date()}</td>
        <td>{course.dates && course.dates.length ? course.dates.length : 0} ganger</td>
        <td>{course.price} kr</td>
        <td>{availableSpots}</td>
        <td className={availableSpots > 0 ? 'pointer' : 'disabled'} onClick={e => availableSpots > 0 ? options.preOrderChecks(course._id) : null}>{availableSpots > 0 ? 'Bestill kurs' : 'Kurset er fullt'}</td>
      </tr>
      {expanded && <>
        <tr className='course-row-expanded'>
          <td></td>
          <td><strong>Instruktør</strong>: {instructorsForCourse.length > 0 ? instructorsForCourse.map(instructor => instructor ? instructor.name : 'Foreløpig ukjent').join(', ') : 'Foreløpig ukjent'}</td>
          <td><strong>Badetid</strong>: {durationDate.getMinutes()} min</td>
          <td colSpan={7} className='pre-wrap'><strong>Informasjon</strong>: {course.info}</td>
        </tr>
        {loggedInUser && (loggedInUser.roles.includes('instructor') || loggedInUser.roles.includes('admin')) && 
          <tr className='course-row-expanded'>
            <td></td>
            <td colSpan={9}>
              {course.participants && course.participants.length > 0 ?
                <>
                  <strong>Påmeldte:</strong>
                  {course.participants.map((participant, pIndex) => <p key={`participant-${pIndex}`} className='no-margin'>
                    {typeof participant !== 'string' && `${participant.firstName} ${participant.lastName}`}
                    {/* <span onClick={null}>Se foreldre</span> */}
                  </p>)}
                </>
              :
                <p className='no-margin'>Ingen påmeldte</p>
              }
            </td>
          </tr>
        }
      </>}
    </>
  ;
}
