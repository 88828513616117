import './newWebsiteModal.css';
import Button from "../../Buttons/Button";
import { NewWebsiteInfo } from "../../Contact/NewWebsiteInfo";
import { Modal } from "../Generic/Modal";

export const NewWebsiteModal = ({ options }) => {

  return (
    <Modal options={{ ...options, title: 'Ny nettside' }}>
      <NewWebsiteInfo />
      <div className="actions">
        <Button onClickFn={options.close}>Greit</Button>
      </div>
    </Modal>
  );
}
