import { Link } from 'react-router-dom';
import './vippsPayment.css';

export const VippsPayment = ({ payment }) => {
  if (!payment) return null;
  if (payment.state === 'ABORTED') {
    return (<div className="vipps-payment">
      <div className="section">
        <div className="">
          <h2>Bestilling avbrutt!</h2>
          <p>Bestillingen din ble avbrutt. Du har ikke blitt belastet for dette kurset.</p>
          <p>Hvis dette er en feil, kan du prøve å gjennomføre bestillingen på nytt.</p>
        </div>
      </div>
    </div>);
  } else if (payment.state === 'AUTHORIZED') {
    return (
      <div className="vipps-payment">
        {payment ?
          <div className="section">
            <div className="">
              <h2>Takk for bestillingen!</h2>
              <p>Dere er nå påmeldt kurs!</p>
              <p>Vi har sendt en ordrebekreftelse med detaljer til din e-postadresse.</p>
            </div>
            <div className="">
              {/* <p>Du kan finne oversikt over kurset i profilen din under "Kurshistorikk".</p> */}
              <p>Les gjerne gjennom <Link to="/regler-og-betingelser">kursregler og betingelser</Link> og "ofte stilte spørsmål" før dere møter på kurset.</p>
            </div>
            <div className="">
              <p>Vi gleder oss til å møte dere i bassenget!</p>
            </div>
          </div>
        :
          <p>Fant ikke din ordre</p>
        }
      </div>
    );
  }
}
