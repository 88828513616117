import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import HomePage from './components/Pages/Home/Home';
// import CoursesPage from './components/Pages/Courses/Courses';
// import AboutPage from './components/Pages/About/About';
// import ContactPage from './components/Pages/Contact/Contact';
// import LoginPage from './components/Pages/Login/Login';
// import VippsRedirect from './components/Vipps/VippsRedirect';


// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <HomePage />,
//   }, {
//     path: "kurs",
//     element: <CoursesPage />,
//   }, {
//     path: "om-oss",
//     element: <AboutPage />,
//   }, {
//     path: "kontakt",
//     element: <ContactPage />,
//   }, {
//     path: "login",
//     element: <LoginPage />
//   }, {
//     path: "login/vipps-redirect",
//     element: <VippsRedirect />
//   }
// ]);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );

import { BrowserRouter } from 'react-router-dom';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
