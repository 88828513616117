import './button.css';

const Button = ({ children, bgc, color, onClickFn, type = 'button', classes = '' }) => {
  if (!children) {
    console.error('No children provided to Button');
    return;
  }
  if (typeof children !== 'string') throw Error('Children is not string');
  if (classes) classes = ' ' + classes;
  return <button className={"btn" + classes} type={type} style={{ backgroundColor: bgc, color: color }} onClick={onClickFn}>{children}</button>
}

export default Button;
