import './newWebsiteInfo.css';

export const NewWebsiteInfo = () => {
  return <div className="nw-info">
    <p>Vi lager ny nettside!</p>
    <p>Foreløpig ber vi om at du tar kontakt med oss via e-post eller telefon dersom du ønsker å bestille kurs.</p>
    <p>Telefon: <a href="tel:+4792060758">920 60 758</a></p>
    <p>E-post: <a href="mailto:post@badenymfene.no">post@badenymfene.no</a></p>
  </div>
}
