import config from "../../config";

const vippsRequest = async (optionsParam) => {
  if (!optionsParam.path) throw new Error('No path in options parameter');
  
  const tokenStr = localStorage.getItem('token');
  if (!tokenStr) throw new Error("Could not find token. Are you logged in?");
  const tokenObject = JSON.parse(tokenStr);

  const idToken = tokenObject.id_token;
  const accessToken = tokenObject.access_token;
  const bearerToken = `${tokenObject.token_type} ${idToken}`;
  if (!accessToken) throw new Error('No OAuth2 access token');


  const options = {
    method: optionsParam.method || 'GET',
    headers: {
      Authorization: bearerToken,
      'Content-Type': 'application/json'
    }
  }

  if (optionsParam.body) options.body = JSON.stringify(optionsParam.body);

  const res = await fetch(`${config.api.baseUrl}${optionsParam.path}?at=${accessToken}`, options);

  return res;
}

export const payment = async (userId, childId, courseId) => {
  if (!courseId) throw new Error("No courseId");

  const res = await vippsRequest({
    path: '/vipps/payment',
    method: 'POST',
    body: {
      userId: userId,
      childId: childId,
      courseId: courseId
    }
  });
  if (!res.ok) {
    switch (res.status) {
      case 400:
        // console.log("Bad request", res);
        // throw new Error("Bad request", res);
        const data = await res.json();
        if (data.message === "Child is already in course") data.message = "Barnet er allerede påmeldt dette kurset";
        return { error: data };
      case 401:
        console.log("Unauthorized", res);
        // throw new Error("Unauthorized", res);
        return;
      case 403:
        console.log("Forbidden", res);
        // throw new Error("Forbidden", res);
        return;
      case 404:
        console.log("Not found", res);
        // throw new Error("Not found", res);
        return;
      default:
        console.log("Unknown error", res);
        // throw new Error("Unknown error", res);
        return;
    }
  }
  const data = await res.json();
  if (!data) throw new Error("Error in payment", data);
  if (res.status === 400) throw new Error(data);
  window.location.href = data.redirectUrl;
}

export const getPayment = async (paymentId) => {
  if (!paymentId) throw new Error("No paymentId");

  try {
    const res = await vippsRequest({
      path: `/vipps/payment/${paymentId}`,
      method: 'GET'
    });
    if (!res.ok) {
      switch (res.status) {
        case 400:
          throw new Error("Bad request", res);
        case 401:
          throw new Error("Unauthorized", res);
        case 403:
          throw new Error("Forbidden", res);
        case 404:
          throw new Error("Not found", res);
        default:
          throw new Error("Unknown error", res);
      }
    }
    const data = await res.json();
    if (!data) throw new Error("Error in payment", data);
    return data;
  } catch (error) {
    console.log(error);
  }
};
