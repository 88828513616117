// import { Children } from 'react';
import './customSelect.css'

export const CustomSelect = ({ options, children }) => {

  return (
    <div className="custom-select">
      <select onChange={options.onChange} defaultValue={0}>
        <option value="0" disabled>{options.defaultText || 'Velg'}</option>
        {options.items && options.items.length > 0 && options.items.map((item, index) => {
          const selectTitle = options.title || '';
          return <option key={`${selectTitle}CustomSelectKey-${index}`} value={item.value}>{item.text}</option>
        })}
        {/* {Children.map((children, (child, index) => (<options value="" key={`custom-select-key-${index}`}>{childText}</options>)))} */}
      </select>
    </div>
  );
}
