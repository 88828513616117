import './courseTable.css';
import { payment } from '../../functions/vipps';
import { LoggedInUserContext } from '../../context';
import { useContext, useEffect, useState } from 'react';
import { LoginModal } from '../Modal/Login/LoginModal';
import { CourseTableRow } from './CourseTableRow';
import { SelectChildModal } from '../Modal/SelectChild/SelectChildModal';
import config from '../../config';
import { addCourse, deleteCourse, editCourse } from '../../functions/fetch';
import { NewWebsiteModal } from '../Modal/NewWebsite/NewWebsiteModal';
import { SimpleSpinner } from '../Spinner/SimpleSpinner';
import { ErrorModal } from '../Modal/ErrorModal/ErrorModal';

export const CourseTable = ({ children, options }) => {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectChildModalIsOpen, setSelectChildModalIsOpen] = useState(false);
  const [orderCourseId, setOrderCourseId] = useState(null);
  const [courses, setCourses] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [newWebsiteModalIsOpen, setNewWebsiteModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchInstructors = async () => {
      const res = await fetch(`${config.api.baseUrl}/users/instructors`);
      const instructors = await res.json();
      setInstructors(instructors);
    }
    fetchInstructors();
  }, []);

  useEffect(() => {
    setCourses(children);
  }, [children]);

  const preOrderChecks = async (id) => {
    // if (loggedInUser && loggedInUser.roles.includes('admin')) {
      if (!loggedInUser) {
        setModalIsOpen(true);
        return;
      }
      setSelectChildModalIsOpen(true);
      setOrderCourseId(id);
    // } else {
    //   setNewWebsiteModalIsOpen(true);
    //   return;
    // }
  }

  const orderFn = async (childId) => {
    if (!loggedInUser) return;
    if (!childId) {
      setError('Du må velge et barn for å kunne bestille kurset');
      return;
    }
    setLoading(true);
    try {
      const res = await payment(loggedInUser._id, childId, orderCourseId);
      if (res.error) {
        setError(res.error.message);
        // setErrorModalIsOpen(true);
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ file: CourseTable.js:61 ~ orderFn ~ error:", error);
      setError(error.message);
      // setErrorModalIsOpen(true);
      setLoading(false);
    }
  }

  const saveEditedCourse = async (editedCourse) => {
    const data = await editCourse(editedCourse);
    if (data) {
      console.log('Updated course!');
    }
    else console.log('Error saving course');
  }

  const saveNewCourse = async (newCourse) => {
    const data = await addCourse(newCourse);
    if (data) {
      // setCourses([ ...courses, data ]);
      console.log('Added course!');
      // setEditMode(false);
    }
    else console.log('Error saving course');
  }

  const removeCourse = async (id) => {
    const data = await deleteCourse(id);
    if (data) {
      setCourses(courses.filter(c => c._id !== id));
      console.log('Deleted course!');
    }
    else console.log('Error deleting course');
  }

  const clearError = () => setError(null);

  return (
    <div className="course-table">
      <table>
        {((loggedInUser && loggedInUser.roles.includes('admin')) || (courses && courses.length > 0)) && <thead>
          <tr>
            <th></th>
            <th>Alder</th>
            <th>Nivå</th>
            <th>Tidspunkt</th>
            <th>Dag</th>
            <th>Startdato</th>
            <th>Varighet</th>
            <th>Pris</th>
            <th>Ledige plasser</th>
            <th></th>
          </tr>
        </thead>}
        <tbody>
          {loggedInUser && loggedInUser.roles.includes('admin') && <tr><td className='pointer' colSpan={10} style={{ textAlign: 'center' }} onClick={options.newCourse}>Klikk her for å legge til et nytt kurs</td></tr>}
          {courses && courses.length > 0 && courses.map((course, i) => (<CourseTableRow key={`course-list-${i}`} options={{ data: course, instructors, preOrderChecks, saveEditedCourse, saveNewCourse, removeCourse }} />))}
        </tbody>
      </table>
      {loggedInUser && <SelectChildModal options={{ isOpen: selectChildModalIsOpen, close: () => setSelectChildModalIsOpen(false), onConfirm: orderFn, errorMessage: error, clearError }} />}
      <LoginModal options={{ isOpen: modalIsOpen, close: () => setModalIsOpen(false) }} />
      <ErrorModal options={{ isOpen: errorModalIsOpen, close: () => setErrorModalIsOpen(false) }}><p>{error}</p></ErrorModal>
      <NewWebsiteModal options={{ isOpen: newWebsiteModalIsOpen, close: () => setNewWebsiteModalIsOpen(false) }} />
      {loading && <div className="loading-overlay"><SimpleSpinner /></div>}
    </div>
  );
}
