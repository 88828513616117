// import { ModalOpenContext } from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './modal.css';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
// import { useContext, useState } from 'react';

export const Modal = ({ options, children }) => {
  // const { modalIsOpen, setModalIsOpen } = useContext(ModalOpenContext);

  const opts = {
    ...{
      backdrop: true,
      showCloseButton: true,
      closeOnOutsideClick: true,
      title: ''
    },
    ...options
  }

  if (!opts.isOpen) return null;

  return (
    <div className='modal-wrapper'>
      {opts.backdrop &&
        <div className='backdrop' onClick={opts.closeOnOutsideClick ? opts.close : null}></div>
      }
      <div className='modal' style={opts.style ? opts.style : null}>
        {opts.showCloseButton &&
          <>
            <div className="title-bar">
              <h2 className='title'>{opts.title ? opts.title : null}</h2>
              <div className="close pointer" onClick={opts.close}>
                <FontAwesomeIcon icon={faXmark} size='xl' />
              </div>
            </div>
            <hr />
          </>
        }
        <div className="content">
          {children}
        </div>
      </div>
    </div>
  );
}