import { Link } from 'react-router-dom';
import OrderButton from '../Buttons/Button';
import BadenymfeneLogo from '../Logo/BadenymfeneLogo';
import './footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const footer = () => {

  return (
    <div className='footer'>
      <div className="top">
        <div className="left">
          <div className="logo">
            <h2>
              <BadenymfeneLogo />
            </h2>
          </div>
          <div className="footer-desc">
            <p>Vi har mange kurs fordelt på flere kurssteder i Oslo/Viken.</p>
            <p>Finn et kurs i nærheten av din familie.</p>
          </div>
          <div className="btn-order">
            <Link to="/kurs"><OrderButton color="#000">Bestill kurs</OrderButton></Link>
          </div>
        </div>
        <div className="right">
          <div className="company-links">
            <h4>Badenymfene</h4>
            <ul>
              <li>
                <Link to="om-oss">Om oss</Link>
                {/* <a href="#!">Om oss</a> */}
              </li>
              <li>
                <Link to="kontakt">Kontakt</Link>
                {/* <a href="#!">Kontakt</a> */}
              </li>
            </ul>
          </div>
          <div className="useful-links">
            <h4>Praktisk informasjon</h4>
            <ul>
              <li>
                <Link to="regler-og-betingelser">Kursregler og kjøpsbetingelser</Link>
                {/* <a href="#!">Kursregler og kjøpsbetingelser</a> */}
              </li>
              <li>
                {/* <Link to="#!">Hva er babysvømming</Link> */}
              </li>
            </ul>
          </div>
          <div className="social-links">
            <h4>Følg oss!</h4>
            <ul>
              <li><Link to="https://www.facebook.com/profile.php?id=100063604478896" target='_blank'><FontAwesomeIcon icon={faFacebook} size='2x' /></Link></li>
              <li><Link to="https://www.instagram.com/badenymfene_babysvomming/" target='_blank'><FontAwesomeIcon icon={faInstagram} size='2x' /></Link></li>
              {/* <li><Link to="youtube"><FontAwesomeIcon icon={faYoutube} size='2x' /></Link></li> */}
            </ul>
          </div>
        </div>
      </div>
      <hr />
      <div className="bottom">
        <p className="company-info">Copyright &copy; Badenymfene Baby og Småbarnssvømming AS org. 933132838, All rights reserved</p>
        <a href="mailto:post@badenymfene.no">post@badenymfene.no</a>
      </div>
    </div>
  );
}

export default footer;
