import { weekdayHelper } from "../../functions/helpers";

export const EditCourseTableRow = ({ course, instructors, options }) => {
  if (!course) return null;
  return <>
    <tr>
      <td className='pointer' onClick={() => options.setEditMode(false)}>Avbryt</td>
      <td><input type="text" placeholder='Alder' value={course.participantsAge} onChange={e => options.setCourse({ ...course, participantsAge: e.target.value})} /></td>
      <td>
        <select name="level" id="" defaultValue={course.experienceLevel || "Nybegynner"} onChange={e => options.setCourse({ ...course, experienceLevel: e.target.value })}>
          <option value="Nybegynner">Nybegynner</option>
          <option value="Viderekommen">Viderekommen</option>
          <option value="Søskensvømming">Søskensvømming</option>
          <option value="Nybegynner/Viderekommen">Nybegynner/Viderekommen</option>
        </select>
      </td>
      <td><input type="time" value={course.timeFrom} onChange={e => options.setCourse({ ...course, timeFrom: e.target.value})} placeholder='Fra tid' /> - <input type="time" value={course.timeTo} onChange={e => options.setCourse({ ...course, timeTo: e.target.value})} placeholder='Til tids' /></td>
      <td>{weekdayHelper(new Date(course.dates[0]).getDay())}</td>
      <td><input type="date" value={new Date(course.dates[0]).toISOString().substring(0, 10)} onChange={e => options.setStartDate(new Date(e.target.value))} placeholder='Startdato' /></td>
      <td><input type="number" value={course.dates.length <= 0 ? 1 : course.dates.length} placeholder='Antall ganger' min={1} max={50} onChange={e => options.calculateDates(parseInt(e.target.value))} /></td>
      <td><input type="number" value={course.price} onChange={e => options.setCourse({ ...course, price: e.target.value})} min={0} step={25} placeholder='Pris' /></td>
      <td><input type="number" value={course.maximumParticipants || 0} onChange={e => options.setCourse({ ...course, maximumParticipants: e.target.value})} min={0} placeholder='Antall plasser' /></td>
      <td className='pointer' onClick={options.saveEdited}>Lagre</td>
    </tr>
    <tr>
      <td>
        <select
          name="instructor"
          id=""
          defaultValue={course.instructors && course.instructors.length > 0 ? course.instructors[0].id : "0"}
          onChange={e => options.setCourse({ ...course, instructors: [e.target.value] })}
        >
          <option value="0" disabled>Velg instruktør</option>
          <option value="1">Foreløpig ukjent</option>
          {instructors.map(instructor => <option key={instructor.id} value={instructor.id}>{instructor.name}</option>)}
        </select>
      </td>
      <td colSpan={8}><textarea placeholder='Informasjon' defaultValue={course.info} onChange={e => options.setCourse({ ...course, info: e.target.value})}></textarea></td>
      {course._id ? <td className="pointer text-delete-color" onClick={options.deleteCourse}>Slett</td> : <td></td>}
    </tr>
  </>
}
