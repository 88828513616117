import './courseCard.css';
import { ReactComponent as Svg } from '../../img/300x200.svg';

const CourseCards = ({ options }) => {
  let image;
  try {
    image = <img alt='Bassengbilde' src={require(`../../img/${options.name.replace(' ', '_').toLowerCase()}.jpg`)} />;
  } catch (err) {
    image = <Svg />;
  }

  return (
    <div className='course-card'>
      <div className="top">
        <h3>{options.name}</h3>
        <p>{`${options.address}, ${options.zipCode} ${options.place}`}</p>
      </div>
      <div className="middle">
        {image}
      </div>
      <div className="bottom">
        <a href={`/kurs/${options._id}`}>Se ledige kurs</a>
      </div>
    </div>
  );
}

export default CourseCards;
