import { useState } from "react";
import Button from "../../Buttons/Button";

export const AddChild = ({ options }) => {
  const [newChild, setNewChild] = useState({});

  const saveNewChild = async (e) => {
    console.log("🚀 ~ saveNewChild ~ e:", e)
    e.preventDefault();
    if (!newChild.firstName || !newChild.lastName || !newChild.birthdate) {
      console.error('Missing required fields');
      return null;
    };
    await options.saveNewChild(newChild);
  }

  return (
    <form className="child-modal-edit">
      <div className="child-names">
        <div>
          <label htmlFor="child-add-modal-firstName">Fornavn</label>
          <input id="child-add-modal-firstName" type="text" name="firstName" placeholder="Fornavn" required value={newChild.firstName} onChange={e => setNewChild({ ...newChild, firstName: e.target.value })} />
        </div>
        <div>
          <label htmlFor="child-add-modal-lastName">Etternavn</label>
          <input id="child-add-modal-lastName" type="text" name="lastName" placeholder="Etternavn" required value={newChild.lastName} onChange={e => setNewChild({ ...newChild, lastName: e.target.value })} />
        </div>
      </div>
      <div className="child-date-of-birth">
        <div>
          <label htmlFor="child-add-modal-birthdate">Fødselsdato</label>
          <input id="child-add-modal-birthdate" type="date" name="birthdate" placeholder="Fødselsdato" required value={newChild.dateOfBirth} onChange={e => setNewChild({ ...newChild, birthdate: e.target.value })} />
        </div>
      </div>
      <div className="actions">
        <Button onClickFn={() => options.finishEditing()}>Avbryt</Button>
        <Button bgc="#0146F6" color="#fff" type="submit" onClickFn={(e) => saveNewChild(e)}>Lagre</Button>
      </div>
    </form>
  );
}
